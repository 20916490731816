.header {
  text-align: center;
}

body {
  color: #fff;
  background-color: #d3931c;
}

.dark-bg {
  background-color: #d4af32;
}
